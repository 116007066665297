const images = [{
    id: 1,
    src: "PICTURES/1.png",
    alt: "Image 1"
},
{
    id: 2,
    src: "PICTURES/2.png",
    alt: "Image 2 "
},
{
    id: 3,
    src: "PICTURES/3.png",
    alt: "Image 3"
},
{
    id: 4,
    src: "PICTURES/4.png",
    alt: "Image 4"
}
];
export default images;